import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <Box sx={{ p: 1 }}>{children}</Box>}
		</div>
	);
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export function HorizontalTabs({ items, className }) {
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: "100%", backgroundColor: "#131722", borderRadius: "20px", padding: "20px" }} className={className}>
			<Box sx={{ borderBottom: 1, borderColor: "rgba(69, 153, 217, 0.5)" }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					sx={{
						"& .MuiTabs-indicator": {
							backgroundColor: "#4599d9",
							height: 3,
							boxShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
						},
					}}>
					{items.map((item) => {
						return (
							<Tab
								key={item.id}
								label={item.title}
								{...a11yProps(item.id)}
								sx={{
									color: "#fff",
									"&.Mui-selected": {
										color: "#4599d9",
										textShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
									},
									"&:hover": {
										color: "#f74712",
										textShadow: "0 0 10px #f74712, 0 0 20px #f74712",
									},
								}}
							/>
						);
					})}
				</Tabs>
			</Box>
			{items.map((item) => {
				return (
					<CustomTabPanel key={item.id} value={value} index={item.id}>
						{item.component}
					</CustomTabPanel>
				);
			})}
		</Box>
	);
}
