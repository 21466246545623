import React, { useState, useEffect } from "react";
import { Typography, Grid, Button, Box } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import socket from "../../../../socket/Socket.js";

export const AccountsPage = ({ onHide }) => {
	const user = JSON.parse(localStorage.getItem("user"));
	const app_id = 62094;
	// const app_id = 39953;
	const AUTH_URL = `https://oauth.deriv.com/oauth2/authorize?app_id=${app_id}&redirect_uri=http://localhost:3000/auth-success.html&state=${user.id}`;

	const [selectedAccount, setSelectedAccount] = useState(localStorage.getItem("selectedAccount"));
	const [initiated, setInitiated] = useState(false);
	const [accounts, setAccounts] = useState([]);

	useEffect(() => {
		getAccounts();
	}, []);

	const getAccounts = async () => {
		try {
			const response = await axios.post("https://mitserver.app:9005/getAccounts", { userId: user.id });

			if (response.status === 200) {
				const accountsData = response.data.data;
				console.log("ACCOUNTS", accountsData);

				if (accountsData[0].account === "") {
					toast.error("No tienes cuentas disponibles. Por favor, Inicia Sesión");
					return;
				}

				let accountsWithUserId = accountsData.map((account) => ({
					...account,
					userId: user.id,
				}));

				user.realAcc = accountsWithUserId[0].account;
				// user.realToken = accountsWithUserId[0].token;
				user.demoAcc = accountsWithUserId[1].account;
				// user.demoToken = accountsWithUserId[1].token;

				console.log("ACCOUNTS", accountsWithUserId);
				if (accountsWithUserId[0].account === null) {
					accountsWithUserId = [];
				}
				setAccounts(accountsWithUserId);
				localStorage.setItem("user", JSON.stringify(user));
				setInitiated(true);
			} else {
				setInitiated(false);
			}
		} catch (error) {
			toast.error("Error al obtener las cuentas. Por favor, intenta nuevamente más tarde.");
		}
	};

	// const handleAccountSelection = (account) => {
	// 	if (account.status === "0" || Number(account.balance) <= 0) {
	// 		toast.error("Esta cuenta está desactivada o no tiene fondos");
	// 		return;
	// 	}

	// 	const combinedAccount = {
	// 		...selectedAccount, // Mantén los datos adicionales de selectedAccount
	// 		...account, // Sobrescribe con los datos de account
	// 		account: account.id, // Asegúrate de que account esté presente
	// 	};
	// 	delete combinedAccount.id;

	// 	const setBalance = store.getState().setBalance;
	// 	setBalance(Number(combinedAccount.balance).toFixed(2));

	// 	const data = { userId: combinedAccount.userId, account: combinedAccount.account };
	// 	socket.emit("allPositions", data);
	// 	socket.emit("openPositions", data);
	// 	socket.emit("allOrders", data);
	// 	// socket.emit("balance", { action: "get", ...data });

	// 	const setSelectedAccount = store.getState().setSelectedAccount;
	// 	setSelectedAccount(combinedAccount); // Actualiza selectedAccount en el store
	// 	localStorage.setItem("selectedAccount", JSON.stringify(combinedAccount));
	// 	setActiveAccount(combinedAccount);
	// 	onHide(false);

	// 	// Actualizar el balance en la lista de cuentas (accounts)
	// 	// let accounts = JSON.parse(localStorage.getItem("accounts")) || [];
	// 	// accounts = accounts.map((acc) => {
	// 	// 	if (acc.id === combinedAccount.account) {
	// 	// 		return { ...acc, balance: Number(combinedAccount.balance).toFixed(2) };
	// 	// 	}
	// 	// 	return acc;
	// 	// });
	// 	// localStorage.setItem("accounts", JSON.stringify(accounts));
	// };

	// useEffect(() => {
	// 	const accounts = JSON.parse(localStorage.getItem("derivAccounts"));
	// 	if (accounts) {
	// 		setInitiated(true);
	// 	}
	// }, []);

	const handleLoginClick = () => {
		window.open(AUTH_URL, "Login", "width=600,height=600");
	};

	const handleAccountSelection = (account) => {
		setSelectedAccount(account.account);
		localStorage.setItem("selectedAccount", JSON.stringify(account));
		setAccounts([...accounts]);
		onHide(false);

		const data = { userId: account.userId, account: account.account };
		socket.emit("allLines", data);
	};

	return (
		<div style={{ backgroundColor: "#131722" }}>
			<Typography sx={{ fontSize: 14, p: 1, textAlign: "center" }} color="text.secondary" gutterBottom>
				Selecciona una Cuenta Deriv
			</Typography>

			<Grid item xs={12} lg={12}>
				<Box display="flex" justifyContent="center" gap={2}>
					<Button
						variant="contained"
						type="submit"
						disabled={initiated}
						onClick={handleLoginClick}
						sx={{
							backgroundColor: initiated ? "#4599d9" : "transparent",
							color: "#fff",
							border: `2px solid ${initiated ? "#4599d9" : "#f74712"}`,
							borderRadius: "15px",
							fontWeight: "bold",
							padding: "10px 20px",
							cursor: "pointer",
							transition: "all 0.3s ease",
							textShadow: "0 0 10px #fff",
							boxShadow: initiated ? "0 0 10px #4599d9, 0 0 20px #4599d9" : "0 0 10px #f74712, 0 0 20px #f74712",
							"&:hover": {
								backgroundColor: initiated ? "#3d87c3" : "rgba(247, 71, 18, 0.2)",
								boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
							},
							"&:disabled": {
								backgroundColor: "#808080",
								border: "2px solid #808080",
								color: "#D3D3D3",
								boxShadow: "none",
							},
						}}>
						{initiated ? "Sesión Iniciada" : "Iniciar Sesión"}
					</Button>

					<Button
						variant="contained"
						type="submit"
						disabled={!initiated}
						onClick={() => {
							setInitiated(false);
							setAccounts([]);
						}}
						sx={{
							backgroundColor: initiated ? "#4599d9" : "transparent",
							color: "#fff",
							border: `2px solid ${initiated ? "#4599d9" : "#f74712"}`,
							borderRadius: "15px",
							fontWeight: "bold",
							padding: "10px 20px",
							cursor: "pointer",
							transition: "all 0.3s ease",
							textShadow: "0 0 10px #fff",
							boxShadow: initiated ? "0 0 10px #4599d9, 0 0 20px #4599d9" : "0 0 10px #f74712, 0 0 20px #f74712",
							"&:hover": {
								backgroundColor: initiated ? "#3d87c3" : "rgba(247, 71, 18, 0.2)",
								boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
							},
							"&:disabled": {
								backgroundColor: "#808080",
								border: "2px solid #808080",
								color: "#D3D3D3",
								boxShadow: "none",
							},
						}}>
						Cerrar Sesión
					</Button>
				</Box>
			</Grid>

			<Grid container spacing={1} style={{ marginTop: 30 }}>
				{accounts &&
					accounts.map((account, index) => {
						const accountType = index === 0 ? "Real Account" : "Demo Account";
						const isActive = JSON.parse(localStorage.getItem("selectedAccount")).account === account.account;
						return (
							<Grid item xs={6} sm={6} md={6} lg={6} xxl={6} key={index}>
								<div
									className="cart-container"
									style={{
										display: "flex", // Flexbox
										flexDirection: "column", // Align items vertically
										justifyContent: "center", // Center items vertically
										alignItems: "center", // Center items horizontally
										maxWidth: 345,
										background: "rgba(0, 0, 0, 0.6)",
										borderRadius: "20px",
										padding: "20px",
										boxShadow: isActive ? "0 0 15px #4599d9, 0 0 30px #4599d9, 0 0 45px #4599d9" : "none",
										transition: "all 0.3s ease",
									}}>
									<p
										className="container-description cart-capitalize"
										style={{
											fontWeight: 800,
											color: "#fff",
											textShadow: "0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff",
											marginBottom: "15px",
											textAlign: "center", // Center text within the p element
										}}>
										{accountType}
									</p>
									<Button
										onClick={() => handleAccountSelection(account)}
										sx={{
											backgroundColor: isActive ? "#4599d9" : "transparent",
											color: "#fff",
											border: `2px solid ${isActive ? "#4599d9" : "#f74712"}`,
											borderRadius: "15px",
											fontWeight: "bold",
											padding: "10px 20px",
											cursor: "pointer",
											transition: "all 0.3s ease",
											textShadow: "0 0 10px #fff",
											boxShadow: isActive ? "0 0 10px #4599d9, 0 0 20px #4599d9" : "0 0 10px #f74712, 0 0 20px #f74712",
											"&:hover": {
												backgroundColor: isActive ? "#3d87c3" : "rgba(247, 71, 18, 0.2)",
												boxShadow: "0 0 15px #4599d9, 0 0 30px #4599d9",
											},
										}}>
										{isActive ? "Active" : "Select"}
									</Button>
									<p
										style={{
											color: "#fff",
											marginTop: "15px",
											textShadow: "0 0 5px #fff",
											textAlign: "center", // Center text within the p element
										}}>
										{account.account}
									</p>
								</div>
							</Grid>
						);
					})}
			</Grid>
		</div>
	);
};
