import { create } from "zustand";

const initialState = {
	lastBar: null,
	selectedAccount: null,
	symbolInfo: null,
	derivWS: null,
	balance: null,
	chart: null,
	version: "V1.0.10",
	symbols: null,
	openPositions: null,
	customCandles: null,
};

const apiStore = (set, get) => ({
	...initialState,
	setLastBar: (lastBar) => set({ lastBar }),
	setSelectedAccount: (selectedAccount) => set({ selectedAccount }),
	setSymbolInfo: (symbolInfo) => set({ symbolInfo }),
	setDerivWS: (derivWS) => set({ derivWS }),
	setBalance: (balance) => set({ balance }),
	setChart: (chart) => set({ chart }),
	setVersion: (version) => set({ version }),
	setSymbols: (symbols) => set({ symbols }),
	setOpenPositions: (openPositions) => set({ openPositions }),
	setCustomCandles: (customCandles) => set({ customCandles }),
	reset: () => set(initialState),
});

export const store = create(apiStore);
