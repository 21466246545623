import { store } from "../../store/store";
import socket from "../../socket/Socket.js";
import { toast } from "react-toastify";
import axios from "axios";
import { symbol } from "zod";

// const appId = "62094";
const appId = 62094;
// const appId = 39953;
const derivWS = new WebSocket("wss://ws.derivws.com/websockets/v3?app_id=" + appId);
store.getState().setDerivWS(derivWS);
const connectionDStatus = document.getElementById("socketDeriv");
const subscriptions = new Map();
const ping_interval = 12000;
var reconnectInterval = 1000;
let interval;
let params = null;
let activeSymbol = {};
const apiUrl = process.env.REACT_APP_API_URL;
let symbolsCache = null;
let multiplierAssets = [];
let oldPeriod = null;

function initDerivWSConnection() {
	if (derivWS === null || derivWS.readyState === 0) {
		derivWS.addEventListener("open", (event) => {
			connectionDStatus.style.backgroundColor = "green";
			const sendMessage = JSON.stringify({ ping: 1 });
			derivWS.send(sendMessage);
			reconnectInterval = 1000;
			interval = setInterval(() => {
				const sendMessage = JSON.stringify({ ping: 1 });
				derivWS.send(sendMessage);
			}, ping_interval);
		});
	}
}

initDerivWSConnection();

derivWS.addEventListener("message", (event) => {
	try {
		const receivedMessage = JSON.parse(event.data);
		if (receivedMessage.msg_type === "ohlc") {
			const ohlc = receivedMessage.ohlc;
			if (ohlc.symbol === activeSymbol.symbol && ohlc.granularity === activeSymbol.period) {
				const symbol = ohlc.symbol;
				const subs = subscriptions.get(symbol);
				if (!subs) {
					console.warn(`No subscription found for symbol: ${symbol}`);
					return;
				}

				let epochTime = (ohlc.epoch * 1000).toString();

				if (epochTime.length < 13) {
					const difference = 13 - epochTime.length;
					epochTime = epochTime + "0".repeat(difference);
				}

				const formattedTimeString = epochTime + ".000";
				const formattedTime = parseFloat(formattedTimeString);

				const lastBar = {
					time: formattedTime,
					open: parseFloat(ohlc.open),
					close: parseFloat(ohlc.close),
					high: parseFloat(ohlc.high),
					low: parseFloat(ohlc.low),
				};

				store.getState().setLastBar(ohlc);
				subs.onRealtimeCallback(lastBar);
			}
		}
	} catch (error) {
		console.log("ERROR ON OHLC", error);
	}
});

derivWS.addEventListener("close", (event) => {
	console.log("DERIVWS CLOSE", event);
	connectionDStatus.style.backgroundColor = "red";
	clearInterval(interval);
	setTimeout(() => {
		reconnectInterval = Math.min(reconnectInterval * 2, 30000);
		initDerivWSConnection();
	}, reconnectInterval);
});

derivWS.addEventListener("error", (event) => {
	console.log("DERIVWS ERROR", event);
	connectionDStatus.style.backgroundColor = "red";
	setTimeout(() => {
		reconnectInterval = Math.min(reconnectInterval * 2, 30000);
		initDerivWSConnection();
	}, reconnectInterval);
});

derivWS.addEventListener("disconnect", (event) => {
	console.log("DERIVWS DISCONNECTED", event);
	connectionDStatus.style.backgroundColor = "red";
	setTimeout(() => {
		reconnectInterval = Math.min(reconnectInterval * 2, 30000);
		initDerivWSConnection();
	}, reconnectInterval);
});

function waitForConnection(socket, interval) {
	return new Promise((resolve, reject) => {
		const checkConnection = () => {
			if (socket.readyState === WebSocket.OPEN) {
				resolve();
			} else if (socket.readyState === WebSocket.CLOSED) {
				reject(new Error("WebSocket connection closed"));
			} else {
				setTimeout(checkConnection, interval);
			}
		};
		checkConnection();
	});
}

function getPeriod(resolution) {
	const resolutions = ["1T", "1", "2", "3", "5", "15", "30", "60", "120", "240", "1D"];
	const resolutionValues = [60, 60, 120, 180, 300, 900, 1800, 3600, 7200, 14400, 86400];
	const index = resolutions.indexOf(resolution);
	if (index === -1) {
		return 60; // Default value
	}
	return resolutionValues[index];
}

const getSymbolsInfo = async (symbol1) => {
	try {
		if (symbolsCache) {
			return symbolsCache.find((symbol) => symbol.code === symbol1);
		}

		const response = await axios.post(`https://mitserver.app:9005/getList`, {
			table: "symbols",
		});

		if (response.status === 200) {
			symbolsCache = response.data;
			const selectedSymbol = symbolsCache.find((symbol) => symbol.code === symbol1);
			return selectedSymbol;
		}
	} catch (error) {
		throw error;
	}
};

export async function setSymbolStorage(symbolItem) {
	try {
		const additionalSymbolInfo = await getSymbolsInfo(symbolItem.symbol);
		const { multiplier2, multiplier3, derivMultiplier, derivMultiplier2, derivMultiplier3, pipValue, stopOut, tickSize } = additionalSymbolInfo;

		var symboltoDrag = {
			type: symbolItem.type || symbolItem.market,
			displayName: symbolItem.ticker || symbolItem.display_name,
			symbol: symbolItem.symbol,
			pip: symbolItem.pip || tickSize,
			exchangeOpen: symbolItem.exchangeOpen || 1,
			submarket: symbolItem.submarket,
			ticker: symbolItem.ticker || symbolItem.display_name,
			exchange: "MIT",
			contractSize: symbolItem.contractSize || symbolItem.display_name.toLowerCase().includes("step") ? 10 : 1,
			derivMultiplier,
			derivMultiplier2,
			derivMultiplier3,
			multiplier2,
			multiplier3,
			pipValue,
			stopOut,
		};
		localStorage.setItem("symbolInfo", JSON.stringify(symboltoDrag));
		store.getState().setSymbolInfo(symboltoDrag);
		// return symboltoDrag;
	} catch (error) {
		toast.error("Símbolo no encontrado");
		console.log("ERROR SET SYMBOL STORAGE", error);
	}
}

export async function getHistoryDWS(derivData) {
	const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
	const tvWidget = store.getState().chart;
	params = derivData;
	let per = derivData.period;

	console.log("OLD PERIOD", oldPeriod);
	if (oldPeriod === "1T") {
		tvWidget.activeChart().setChartType(1);
	}

	if (derivData.period === "1T") {
		per = "1";
		tvWidget.activeChart().setChartType(3);
		oldPeriod = "1T";
	} else {
		oldPeriod = derivData.period;
		console.log("SETTING OLD PERIOD", oldPeriod);
	}

	const period = getPeriod(per);
	let message = {};
	if (derivData.firstTime) {
		message = JSON.stringify({
			ticks_history: derivData.symbol,
			adjust_start_time: 1,
			count: 500,
			end: "latest",
			style: derivData.period === "1T" ? "ticks" : "candles",
			granularity: derivData.period === "1T" ? null : period,
		});

		let allPos = {
			symbol: derivData.symbol,
			userId: selectedAccount.userId,
			account: selectedAccount.account,
		};

		socket.emit("allLines", allPos);
	} else {
		let start = derivData.from;
		let end = derivData.to;
		if (derivData.period === "1T") {
			start = Math.round(new Date(derivData.from).getTime() / 1000);
			end = Math.round(new Date(derivData.to).getTime() / 1000);
		}

		if (derivData.period === "1T") {
			message = JSON.stringify({
				ticks_history: derivData.symbol,
				adjust_start_time: 1,
				start: start,
				end: end,
				style: "ticks",
			});
		} else {
			message = JSON.stringify({
				ticks_history: derivData.symbol,
				adjust_start_time: 1,
				start: start,
				end: end,
				style: "candles",
				granularity: period,
			});
		}
	}

	derivWS.send(message);
	let candles = null;

	if (derivData.period === "1T") {
		const tempCandles = await waitForMessageOfType("history");
		const history = tempCandles.history;
		candles = history.prices.map((price, index) => ({
			close: price,
			epoch: history.times[index],
		}));
	} else {
		const tempCandles = await waitForMessageOfType("candles");
		candles = tempCandles.candles;
	}
	return candles;
}

export function waitForMessageOfType(messageType) {
	return new Promise((resolve, reject) => {
		derivWS.onmessage = async (event) => {
			const receivedMessage = JSON.parse(event.data);
			// console.log("DERIVWS MESSAGE", receivedMessage);
			try {
				switch (receivedMessage.msg_type) {
					case messageType:
						resolve(receivedMessage);
						break;
					default:
						// logger.info(`DEFAULT MESSAGE WSDERIV ${JSON.stringify(receivedMessage)}`);
						break;
				}
			} catch (error) {
				reject(error);
			}
		};
	});
}

export function getSymbols() {
	return new Promise(async (resolve, reject) => {
		try {
			const completeSymbols = [];
			await waitForConnection(derivWS, 100);

			const getSymbolsRequest = JSON.stringify({
				asset_index: 1,
			});

			derivWS.send(getSymbolsRequest);

			const handleData = (event) => {
				const msgData = JSON.parse(event.data);
				const msgType = msgData.msg_type;

				if (msgType === "asset_index") {
					multiplierAssets = [];
					msgData.asset_index.forEach((asset) => {
						const hasMultiplier = asset[2].some((option) => option[0] === "multiplier");
						if (hasMultiplier) {
							multiplierAssets.push(asset[1]);
						}
					});
					const getSymbolsRequest = JSON.stringify({
						active_symbols: "brief",
						product_type: "basic",
					});
					derivWS.send(getSymbolsRequest);
				}

				if (msgType === "active_symbols") {
					const activeSymbols = msgData.active_symbols.filter(
						(symbol) => symbol.exchange_is_open === 1 && multiplierAssets.includes(symbol.display_name)
					);
					// console.log("COMPLETE SYMBOLS", activeSymbols);

					const completeSymbols = activeSymbols.map((symbol) => ({
						type: symbol.market,
						displayName: symbol.display_name,
						symbol: symbol.symbol,
						pip: symbol.pip,
						exchangeOpen: symbol.exchange_is_open,
						submarket: symbol.submarket,
						ticker: symbol.display_name,
						exchange: "MIT",
						contractSize: symbol.market === "forex" ? 100000 : 1,
					}));
					resolve(completeSymbols);
				}
			};

			const handleError = (error) => {
				derivWS.removeEventListener("message", handleData);
				onErrorCallback(error);
				reject(error);
			};

			derivWS.addEventListener("message", handleData);
			derivWS.addEventListener("error", handleError);

			derivWS.onerror = function (err) {
				reject(err);
			};
		} catch (err) {
			reject(err);
		}
	});
}

export function subscribeOnStream(symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) {
	var period = getPeriod(resolution);
	activeSymbol.symbol = symbolInfo.ticker;
	activeSymbol.period = period;
	const subscriptionItem = {
		symbol: symbolInfo.ticker,
		period: period,
		onRealtimeCallback: onRealtimeCallback,
		subscriptionId: "",
	};

	subscriptions.set(symbolInfo.ticker, subscriptionItem);
	const message = JSON.stringify({
		ticks_history: symbolInfo.ticker,
		adjust_start_time: 1,
		count: 2002,
		end: "latest",
		style: "candles",
		granularity: period,
		subscribe: 1,
	});
	derivWS.send(message);

	// setTimeout(() => {
	// 	const tvWidget = store.getState().chart;
	// 	tvWidget.activeChart().setSymbol("EUR/USD");
	// }, 3000);
	// setTimeout(() => {
	// 	const tvWidget = store.getState().chart;
	// 	tvWidget.activeChart().setSymbol("EUR/GBP");
	// }, 9000);
}

export function unsubscribeFromStream() {
	subscriptions.forEach((subscriptionItem, symbol) => {
		if (subscriptionItem.symbol !== activeSymbol.symbol) {
			derivWS.send(
				JSON.stringify({
					forget: subscriptionItem.subscriptionId,
				})
			);
			subscriptions.delete(symbol);
		}
	});
}

export async function getLastCloses(granularity) {
	try {
		const message = JSON.stringify({
			ticks_history: activeSymbol.symbol,
			adjust_start_time: 1,
			count: 50,
			end: "latest",
			style: "candles",
			granularity: granularity,
		});

		derivWS.send(message);
		const candles = await waitForMessageOfType("candles");

		if (!candles || !candles.candles) {
			console.error("No se recibieron datos de velas");
			return [];
		}

		const filteredCandles = candles.candles.map((candle) => ({
			close: candle.close,
			// high: candle.high,
			// low: candle.low,
			open: candle.open,
			date: new Date(candle.epoch * 1000).toISOString(),
		}));
		console.log("FILTERED CANDLES", filteredCandles);
		// return filteredCandles;
		store.getState().setCustomCandles(filteredCandles);
	} catch (error) {
		console.error(error.message);
		return [];
	}
	setInterval(async () => {
		try {
			console.log("GET LAST CLOSES", activeSymbol.symbol, activeSymbol.period);
			const message = JSON.stringify({
				ticks_history: activeSymbol.symbol,
				adjust_start_time: 1,
				count: 50,
				end: "latest",
				style: "candles",
				granularity: granularity,
			});

			derivWS.send(message);
			const candles = await waitForMessageOfType("candles");

			if (!candles || !candles.candles) {
				console.error("No se recibieron datos de velas");
				return [];
			}

			const filteredCandles = candles.candles.map((candle) => ({
				close: candle.close,
				// high: candle.high,
				// low: candle.low,
				// open: candle.open,
				date: new Date(candle.epoch * 1000).toISOString(),
			}));
			console.log("FILTERED CANDLES", filteredCandles);
			// return filteredCandles;
			store.getState().setCustomCandles(filteredCandles);
		} catch (error) {
			console.error(error.message);
			return [];
		}
	}, 86400000);
}

function waitForSymbolInfo(maxRetries = 10, delay = 500) {
	return new Promise((resolve, reject) => {
		let retries = 0;

		const checkSymbolInfo = () => {
			const symbolInfo = store.getState().symbolInfo;

			if (symbolInfo && symbolInfo.symbol) {
				resolve(symbolInfo);
			} else if (retries < maxRetries) {
				retries++;
				setTimeout(checkSymbolInfo, delay);
			} else {
				reject(new Error("symbolInfo no está disponible después de varios intentos"));
			}
		};

		checkSymbolInfo();
	});
}
