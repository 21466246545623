import { subscribeOnStream, unsubscribeFromStream, getSymbols, getHistoryDWS, setSymbolStorage, getLastCloses } from "./streaming.js";
import { store } from "../../store/store.js";

const lastBarsCache = new Map();
let firstTime = true;
const configurationData = {
	supported_resolutions: ["1T", "1", "2", "3", "5", "15", "30", "60", "120", "240", "1D"],
	exchanges: [],
	symbols_types: [],
};
let bars = [];

function calculatePriceScale(pip) {
	const decimalPlaces = getDecimalPlaces(pip);
	return Math.pow(10, decimalPlaces);
}

function getDecimalPlaces(num) {
	const match = ("" + num).match(/(?:\.(\d+))?$/);
	if (!match) {
		return 0;
	}
	return match[1] ? match[1].length : 0;
}

export default {
	onReady: (callback) => {
		setTimeout(() => callback(configurationData));
	},
	searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
		// const symbols = await getSymbols();
		// const newSymbols = symbols.filter(({ symbol }) => symbol.toLowerCase().includes(userInput.toLowerCase()));
		// const formattedSymbols = newSymbols.map((symbol) => ({
		// 	symbol: symbol.symbol, //.replace(/^(frx|cry)/, ""),
		// 	full_name: symbol.displayName,
		// 	description: symbol.description || symbol.displayName,
		// 	exchange: symbol.exchange,
		// 	ticker: symbol.ticker,
		// 	type: symbol.type,
		// }));
		// console.log("formattedSymbols", formattedSymbols);
		const formattedSymbols = [];
		onResultReadyCallback(formattedSymbols);
	},
	resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
		const symbols = await getSymbols();
		store.getState().setSymbols(symbols);
		const symbolItem = symbols.find(({ displayName }) => displayName === symbolName);
		if (!symbolItem) {
			onResolveErrorCallback("Cannot resolve symbol");
			return;
		}

		const priceScale = calculatePriceScale(symbolItem.pip);
		const symbolInfo = {
			ticker: symbolItem.symbol,
			name: symbolItem.ticker,
			description: symbolItem.displayName,
			type: symbolItem.type,
			session: "24x7",
			timezone: "Etc/UTC",
			exchange: symbolItem.exchange,
			minmov: 1,
			pricescale: priceScale,
			has_intraday: true,
			visible_plots_set: "ohlc",
			has_weekly_and_monthly: false,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: "streaming",
			has_ticks: true,
			load_last_chart: true,
		};

		setTimeout(() => {
			getLastCloses(60);
		}, 3000);

		setSymbolStorage(symbolItem);
		onSymbolResolvedCallback(symbolInfo);
	},
	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const data = {
			firstTime,
			symbol: symbolInfo.ticker,
			period: resolution,
			from: periodParams.from,
			to: periodParams.to,
		};
		const history = await getHistoryDWS(data);
		firstTime = false;
		const derivBars = history.map((bar) => ({
			time: bar.epoch,
			open: bar.open,
			high: bar.high,
			low: bar.low,
			close: bar.close,
		}));
		var bars = [];
		derivBars.forEach((bar) => {
			bars.push({
				time: bar.time * 1000,
				low: bar.low,
				high: bar.high,
				open: bar.open,
				close: bar.close,
			});
		});

		onHistoryCallback(bars, { noData: bars.length === 0 });
	},
	subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscriberUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(`${symbolInfo.exchange}:${symbolInfo.name}`)
		);
	},
	unsubscribeBars: (subscriberUID) => {
		unsubscribeFromStream(subscriberUID);
	},
};
