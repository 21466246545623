import React, { useState, useEffect } from "react";
import { Box, TextField, Tabs, Tab, IconButton, SvgIcon } from "@mui/material";
import { store } from "../../../../store/store";
import { setSymbolStorage } from "../../streaming";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import socket from "../../../../socket/Socket";
import { Line } from "react-chartjs-2";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler, // Importar Filler para gráficos de área
} from "chart.js";

// Registrar componentes de Chart.js
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler // Registrar Filler
);

const LineChart = ({ history = [], percent }) => {
	const chartRef = React.useRef(null);
	const positiveColor = "#35e737";
	const negativeColor = "#f74712";
	const lineColor = percent >= 0 ? positiveColor : negativeColor;

	const data = {
		labels: history.map((point) => new Date(point.x * 1000).toLocaleTimeString()), // Convierte epoch a tiempo
		datasets: [
			{
				label: "Historial",
				data: history.map((point) => point.y),
				fill: true,
				backgroundColor: (context) => {
					const chart = context.chart;
					const { ctx, chartArea } = chart;

					if (!chartArea) {
						return null;
					}

					const gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
					gradient.addColorStop(0, `${lineColor}70`);
					gradient.addColorStop(1, `${lineColor}00`);

					return gradient;
				},
				borderColor: lineColor,
				tension: 0.4,
				pointRadius: 0,
				pointHoverRadius: 0,
				pointStyle: "line",
				hitRadius: 0,
				pointBorderWidth: 0,
				pointBackgroundColor: "rgba(0, 0, 0, 0)",
			},
		],
	};

	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false,
			},
			tooltip: {
				enabled: false, // Puedes personalizar los tooltips o desactivarlos si deseas
			},
		},
		scales: {
			x: {
				display: false,
				type: "category", // Asegurarse de que la escala 'category' está registrada
			},
			y: {
				display: false,
			},
		},
	};

	return <Line ref={chartRef} data={data} options={options} height={150} />;
};

export function WatchList() {
	const derivWS = store((state) => state.derivWS);
	const [rows, setRows] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState("");
	const [tabValue, setTabValue] = useState(0);
	const [favorites, setFavorites] = useState([]);
	const [symbolData, setSymbolData] = useState({});

	useEffect(() => {
		fetchWatchListData();
	}, []);

	const fetchWatchListData = () => {
		try {
			const state = store.getState();
			const symbols = state.symbols ? Object.values(state.symbols) : [];
			setRows(symbols.map((symbol) => ({ ...symbol, id: symbol.symbol })));
			setLoading(false);
			loadFavorites();
		} catch (error) {
			console.error("ERROR FETCHING WATCH LIST DATA:", error);
			setRows([]);
			setLoading(false);
		}
	};

	const loadFavorites = async () => {
		try {
			const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
			setFavorites(storedFavorites);

			if (storedFavorites.length > 0) {
				const promises = storedFavorites.map(async (favorite) => {
					if (!symbolData[favorite.symbol]) {
						// Solo consulta si los datos no están ya almacenados
						console.log("CONSULTING DATA FOR:", favorite.symbol);
						await derivWS.send(
							JSON.stringify({
								ticks_history: favorite.symbol,
								adjust_start_time: 1,
								count: 24,
								end: "latest",
								style: "candles",
								granularity: "86400", // 24 velas diarias (1 día)
								passthrough: { id: favorite.symbol },
							})
						);
					}
				});

				await Promise.all(promises);
				await getSocketMessages(storedFavorites.length);
				storedFavorites.length > 0 ? setTabValue(1) : setTabValue(0);
			}
		} catch (error) {
			console.error("ERROR LOADING FAVORITES:", error);
		}
	};

	const getSocketMessages = (expectedMessages) => {
		let receivedMessages = 0;
		derivWS.onmessage = function (msg) {
			const msgData = JSON.parse(msg.data);
			const msgType = msgData.msg_type;

			switch (msgType) {
				case "candles":
					if (msgData.passthrough && msgData.passthrough.id) {
						const symbol = msgData.passthrough.id;
						const transformedHistory = msgData.candles.map((candle) => ({
							y: candle.close,
							x: candle.epoch,
						}));

						// Calcular el porcentaje de cambio y la diferencia en pips
						const oldestClose = transformedHistory[0]?.y;
						const newestClose = transformedHistory[transformedHistory.length - 1]?.y;
						let percent = 0;
						let pipDifference = 0;

						if (oldestClose && newestClose) {
							percent = ((newestClose - oldestClose) / oldestClose) * 100;
							pipDifference = newestClose - oldestClose; // Calcular diferencia en pips
						}

						// Almacena los datos históricos en symbolData
						setFavorites((prevFavorites) => {
							const updatedFavorites = prevFavorites.map((fav) => {
								if (fav.symbol === symbol) {
									return {
										...fav,
										history: transformedHistory,
										percent: percent.toFixed(2), // Asegurarnos que percent es un string con dos decimales
										pipDifference: pipDifference.toFixed(2), // Formato a dos decimales
										color: percent >= 0 ? "green" : "red", // Definir color basado en ganancia o pérdida
									};
								}
								return fav;
							});
							localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
							return updatedFavorites;
						});

						receivedMessages += 1;
						if (receivedMessages === expectedMessages) {
							derivWS.onmessage = null;
						}
					}
					break;
				default:
					break;
			}
		};
	};

	const filterRows = (row) => {
		return Object.values(row).some((value) => value.toString().toLowerCase().includes(searchTerm.toLowerCase()));
	};

	const handleFavoriteClick = (row) => {
		let updatedFavorites;
		if (favorites.some((fav) => fav.symbol === row.symbol)) {
			updatedFavorites = favorites.filter((fav) => fav.symbol !== row.symbol);
		} else {
			updatedFavorites = [...favorites, row];

			if (!symbolData[row.symbol]) {
				derivWS.send(
					JSON.stringify({
						ticks_history: row.symbol,
						adjust_start_time: 1,
						count: 24,
						end: "latest",
						style: "candles",
						granularity: "86400",
						passthrough: { id: row.symbol },
					})
				);

				getSocketMessages(1);
			}
		}
		setFavorites(updatedFavorites);
		localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
	};

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const handleCellClick = async (row) => {
		const selectedAccount = JSON.parse(localStorage.getItem("selectedAccount"));
		const tvWidget = store.getState().chart;
		const symbolReady = await setSymbolStorage(row);
		if (tvWidget && tvWidget.chart) {
			await tvWidget.chart().setSymbol(row.displayName, tvWidget.activeChart().resolution());
			let allPos = {
				userId: selectedAccount.userId,
				account: selectedAccount.account,
			};
			socket.emit("allLines", allPos);
		}
	};

	return (
		<Box sx={{ width: "100%", height: "100%", backgroundColor: "#161a25", padding: 1, borderRadius: 2, boxShadow: 3 }}>
			<Tabs value={tabValue} onChange={handleTabChange}>
				<Tab
					label="Todos"
					sx={{
						textTransform: "capitalize",
						fontSize: "0.875rem",
						color: "#fff",
						"&.Mui-selected": {
							color: "#4599d9",
							textShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
						},
						"&:hover": {
							color: "#f74712",
							textShadow: "0 0 10px #f74712, 0 0 20px #f74712",
						},
					}}
				/>
				<Tab
					label="Favoritos"
					sx={{
						textTransform: "capitalize",
						fontSize: "0.875rem",
						color: "#fff",
						"&.Mui-selected": {
							color: "#4599d9",
							textShadow: "0 0 10px #4599d9, 0 0 20px #4599d9",
						},
						"&:hover": {
							color: "#f74712",
							textShadow: "0 0 10px #f74712, 0 0 20px #f74712",
						},
					}}
				/>
			</Tabs>

			<TextField
				label="Buscar"
				variant="outlined"
				size="small"
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
				sx={{
					width: "100%",
					mt: 1,
					mb: 2,
					"& .MuiOutlinedInput-root": {
						height: "35px",
						backgroundColor: "#2a2a2a",
						color: "#fff",
						borderRadius: "5px",
						boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
						"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
							borderColor: "#4599d9",
						},
					},
					"& .MuiInputLabel-root": {
						color: "#fff",
					},
					"& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
						borderColor: "#444",
					},
				}}
			/>

			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 2,
					height: "calc(100% - 100px)",
					overflowY: "auto",
					alignItems: "center",
				}}>
				{(tabValue === 0 ? rows.filter(filterRows) : favorites.filter(filterRows)).map((row) => {
					// Verificar si el símbolo es un favorito
					const isFavorite = favorites.some((fav) => fav.symbol === row.symbol);

					// Obtener el percent y pipDifference del favorito si es un favorito
					const favoriteData = favorites.find((fav) => fav.symbol === row.symbol);
					const percent = favoriteData ? favoriteData.percent : "0"; // Asegúrate de que haya un valor por defecto
					const pipDifference = favoriteData ? favoriteData.pipDifference : "0";

					return (
						<Box
							key={row.symbol}
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "97%",
								backgroundColor: "#131722",
								padding: "3px 8px",
								borderRadius: "8px",
								boxShadow: "0px 5px 8px rgba(69, 153, 217, 0.3)",
								color: "#fff",
								cursor: "pointer",
								position: "relative", // Esto asegura que los elementos absolutos se posicionen correctamente
								"&:hover": {
									backgroundColor: "#333",
								},
							}}
							onClick={() => handleCellClick(row)}>
							{/* Primera fila con displayName y type */}
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
									width: "100%",
								}}>
								<Box sx={{ display: "flex", flexDirection: "column" }}>
									<Box sx={{ fontSize: "0.8rem", fontWeight: "bold", textAlign: "left" }}>{row.displayName}</Box>
									<Box sx={{ fontSize: "0.6rem", color: "#888", textAlign: "left" }}>{row.type}</Box>
								</Box>
								<IconButton
									onClick={(e) => {
										e.stopPropagation();
										handleFavoriteClick(row);
									}}
									sx={{ alignSelf: "flex-start" }}>
									{isFavorite ? (
										<SvgIcon component={StarIcon} sx={{ color: percent > 0 ? "#f74712" : "#35e737" }} />
									) : (
										<StarBorderIcon sx={{ color: "#fff" }} />
									)}
								</IconButton>
							</Box>

							{/* Mostrar Gráfico y Datos de Pips Solo para Favoritos */}
							{isFavorite && (
								<>
									{/* Gráfico LineChart */}
									{Array.isArray(row.history) && (
										<Box sx={{ position: "relative", width: "100%", height: "70px", marginTop: "0px" }}>
											<LineChart history={row.history} percent={percent} /> {/* Pasar el percent al gráfico */}
											{/* Mostrar porcentaje de ganancia/pérdida y diferencia en pips sobre el gráfico */}
											<Box
												sx={{
													position: "absolute",
													bottom: "5px", // Asegura que se posicione en la parte inferior del contenedor gráfico
													right: "10px", // Asegura que se posicione en la parte derecha del contenedor gráfico
													fontSize: "0.7rem",
													fontWeight: "bold",
													color: percent >= 0 ? "green" : "red", // Cambia el color basado en ganancia o pérdida
													backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo semitransparente para mejor visibilidad
													padding: "2px 5px",
													borderRadius: "4px",
												}}>
												{percent}% ({pipDifference} pips)
											</Box>
										</Box>
									)}
								</>
							)}
						</Box>
					);
				})}
			</Box>
		</Box>
	);
}
